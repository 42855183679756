import HomeIcon from '@mui/icons-material/Home';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PreviewIcon from '@mui/icons-material/Preview';
import Family from '../Pages/Family';
import Subscriptions from '../Pages/Subscriptions';
import Topics from '../Pages/Backoffice/Topics/topics';
import Topic from '../Pages/Backoffice/Topics/topic';
import TopicContent from '../Pages/Backoffice/Topics/topicContent';
import Student from '../Pages/Student/student';
import TeacherView from '../Pages/Backoffice/TeacherView/teacherView';
import ExamCheck from '../Pages/ExamCheck/examCheck';
import { ExamsWaitingForReview } from '../Pages/ExamsWaitingForReview/examWaitingForReview';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ExerciseView from '../Pages/Exercise/exercise_view';
import ExerciseNew from '../Pages/Exercise/exercise_new';
import Exercise from '../Pages/Exercise/exercise';
import GradeConfigSelectPage from '../Pages/Backoffice/Topics/grades';
import ConsentScreen from '../Pages/consent/consentScreen';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import LandingPage from '../Pages/Home/landing';
import Login from '../Pages/Login';
import { useCurrentUser } from '../hooks/use-currentUser';
import { auth } from '../firebase';
import Uploads from '../Pages/Uploads/uploads';
import Simulation from '../Pages/Backoffice/Simulation/simulation';
import Grade from '../Pages/Backoffice/Topics/grade';
import Configs from '../Pages/Backoffice/Settings/settings';
import SettingsIcon from '@mui/icons-material/Settings';
import AddStudentFlow from '../components/addStudentFlow';
type IRoute = {
  name: string;
  path: string;
  authGuard: boolean;
  adminGuard: boolean;
  icon?: any;
  element: any;
};
// const applicationRoutes = [];

const pagesRoutes = [
  {
    name: 'home',
    path: '/',
    authGuard: true,
    adminGuard: false,
    icon: <HomeIcon />,
    element: <Family />,
  },

  {
    name: 'subscriptions',
    path: '/subs',
    authGuard: true,
    adminGuard: false,
    icon: <SubscriptionsIcon />,
    element: <Subscriptions />,
  },

  {
    name: 'topic',
    path: '/backoffice/grades/:grade/topic/:id',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <Topic />,
  },
  {
    name: 'topic content',
    path: '/backoffice/grades/:grade/:topic/:id',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <TopicContent />,
  },
  {
    name: 'landing',
    path: '/landing',
    authGuard: false,
    adminGuard: false,
    icon: null,
    element: <LandingPage />,
  },
  {
    name: 'login',
    path: '/login',
    authGuard: false,
    adminGuard: false,
    icon: null,
    element: <Login />,
  },
  {
    name: 'student',
    path: '/student/:id',
    authGuard: true,
    adminGuard: false,
    icon: null,
    element: <Student />,
  },
  {
    name: 'teacher view',
    path: '/teacher-view/:id',
    adminGuard: true,
    authGuard: true,
    icon: null,
    element: <TeacherView />,
  },
  {
    name: 'exam-check',
    path: '/exam-check/:sid/:examId',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <ExamCheck />,
  },
  {
    name: 'exercise',
    path: '/exercise/:id',
    authGuard: true,
    adminGuard: false,
    icon: null,
    element: <ExerciseView />,
  },
  {
    name: 'new_exercise',
    path: '/exercise/new/:gradeId/:topicId/:subtopicId',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <ExerciseNew />,
  },
  {
    name: 'edit_exercise',
    path: '/exercise/edit/:subTopicId/:id',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <Exercise />,
  },
  {
    name: 'upload_test_results',
    path: '/exam/uploads/:studentId/:gradeId/:examId',
    authGuard: false,
    adminGuard: false,
    icon: null,
    element: <Uploads />,
  },
  {
    name: 'waiting for check',
    path: '/waiting-for-review',
    authGuard: true,
    adminGuard: true,
    icon: <PlaylistAddCheckIcon />,
    element: <ExamsWaitingForReview />,
  },

  {
    name: 'preview',
    path: '/backoffice/preview',
    authGuard: true,
    adminGuard: true,
    icon: <PreviewIcon />,
    element: <Simulation />,
  },

  {
    name: '',
    path: '/consent',
    authGuard: true,
    adminGuard: false,
    icon: null,
    element: <ConsentScreen />,
  },
  {
    name: '',
    path: '/new',
    authGuard: false,
    adminGuard: false,
    icon: null,
    element: <AddStudentFlow />,
  },
  {
    name: 'grades',
    path: '/backoffice/grades',
    authGuard: true,
    adminGuard: true,
    icon: <SubscriptionsIcon />,
    element: <GradeConfigSelectPage></GradeConfigSelectPage>,
  },
  {
    name: '',
    path: '/backoffice/grades/:grade/topics',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <Topics />,
  },
  {
    name: '',
    path: 'backoffice/grades/:grade',
    authGuard: true,
    adminGuard: true,
    icon: null,
    element: <Grade />,
  },
  {
    name: 'configs',
    path: 'backoffice/configs',
    authGuard: true,
    adminGuard: true,
    icon: <SettingsIcon />,
    element: <Configs />,
  },
];

const ProtectedAdminRoute = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idTokenResult = await user.getIdTokenResult();
          const claims = idTokenResult.claims;
          console.log('claims1', claims);

          if (claims?.admin) {
            setIsLoading(false);
          } else {
            navigate('/login');
          }
        } catch (error) {
          console.log(error);
          navigate('/login');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return children;
};
const ProtectedAuthRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const { currentUser, currentUserLoaded } = useCurrentUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      const idTokenResult = await user?.getIdTokenResult();
      const claims = idTokenResult?.claims;
      console.log('claims2', claims);
      if (!user?.uid) {
        if (window.location.pathname !== '/') {
          navigate('/login');
        } else {
          navigate('/landing');
        }
      } else {
        const hasConsent = currentUser?.consent;
        if (!hasConsent && !isLoading) {
          navigate('/consent');
        }
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [currentUserLoaded]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return children;
};

export { pagesRoutes, ProtectedAdminRoute, ProtectedAuthRoute };
export type { IRoute };
