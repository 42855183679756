import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Container,
  Box,
} from '@mui/material';
import { useConfig } from '../../../hooks/use-config';
import { Config, Section, Generator, saveConfig } from '../../../dbOps/configs';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';
import { ModernButton } from '../../../designSystem/Buttons/ModernButton';

export default function Configs() {
  const [config, setConfig] = useState<Config>({
    manual: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
    test: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
    training: {
      generator: {
        advanced: 0,
        beginner: 0,
        completed: 0,
        intermediate: 0,
        rehersal: 0,
      },
      scheduler: '',
    },
  });
  const curConfig = useConfig();
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setConfig(curConfig);
  }, [curConfig]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key1: keyof Config,
    key2: keyof Section,
    key3: keyof Generator,
  ) => {
    const value = parseInt(event.target.value);
    setConfig((prevConfig) => {
      const newConfig: Config = { ...prevConfig };
      const updatedGenerator = { ...newConfig[key1].generator, [key3]: value };
      const updatedSection = {
        ...newConfig[key1],
        generator: updatedGenerator,
      };
      newConfig[key1] = updatedSection;
      return newConfig;
    });
  };

  const handleSave = async () => {
    setSaving(true);
    await saveConfig(config);
    setSaving(false);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        {Object.entries(config).map(([key1, value1]) => (
          <Grid item xs={4} key={key1}>
            <Card variant="outlined" sx={{ maxWidth: 400 }}>
              <CardContent>
                <h2>{t(key1)}</h2>
                {Object.entries(value1).map(([key2, value2]) => (
                  <Grid container spacing={2} key={`${key1}-${key2}`}>
                    {typeof value2 === 'string' ? (
                      <Grid item xs={12} style={{ marginTop: 20 }}>
                        <TextField label={t(key2)} value={value2} fullWidth />
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {Object.entries(value2).map(([key3, value3]) => (
                          <Grid
                            item
                            style={{ marginTop: 20 }}
                            key={`${key1}-${key2}-${key3}`}
                          >
                            <TextField
                              label={t(key3)}
                              value={value3}
                              type="number"
                              onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                handleChange(
                                  event,
                                  key1 as keyof Config,
                                  key2 as keyof Section,
                                  key3 as keyof Generator,
                                )
                              }
                              fullWidth
                            />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
        <Box
          sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}
        >
          {/* Version 1: Gradient Button */}
          <ModernButton
            onClick={handleSave}
            disabled={saving}
            startIcon={<SaveIcon sx={{ mr: 1 }} />}
          >
            {saving ? t('Saving...') : t('Save Changes')}
          </ModernButton>
        </Box>
      </Grid>
    </Container>
  );
}
