import { MenuItem, Select } from '@mui/material';

const AvailableGrades: { [key: string]: string } = {
	א: 'grade-1',
	ב: 'grade-2',
	ג: 'grade-3',
	ד: 'grade-4',
	ה: 'grade-5',
	ו: 'grade-6',
	ז: 'grade-7',
	ח: 'grade-8',
	ט: 'grade-9',
	י: 'grade-10',
	יא: 'grade-11',
	יב: 'grade-12'
};
function GetGradeByID(value: string): string | undefined {
	for (const key in AvailableGrades) {
		if (AvailableGrades[key] === value) {
			return key;
		}
	}
	return undefined;
}

function GradeSelect({ value, name, handleOnChange }: any) {
	return (
		<div style={{ minWidth: 120 }}>
			<Select
				labelId="grade-select-label"
				id="grade-select"
				value={value}
				onChange={handleOnChange}
				label="Grade"
				name={name}
				sx={{
					'& .MuiSelect-select': {
						padding: '8px 14px',
						minWidth: '80px',
						textAlign: 'center'
					},
					'& .MuiOutlinedInput-notchedOutline': {
						borderRadius: '8px'
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'primary.main'
					}
				}}
			>
				{Object.entries(AvailableGrades).map(([ key, val ]: any) => (
					<MenuItem
						value={val}
						key={key}
						sx={{
							justifyContent: 'center',
							minWidth: '80px',
							'&:hover': {
								backgroundColor: 'primary.light'
							},
							'&.Mui-selected': {
								backgroundColor: 'primary.main',
								color: 'white',
								'&:hover': {
									backgroundColor: 'primary.dark'
								}
							}
						}}
					>
						{key}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

export { GradeSelect, GetGradeByID, AvailableGrades };
