import * as React from 'react';
import Box from '@mui/material/Box';
import Rating, { RatingProps } from '@mui/material/Rating';
import {
	GridFilterInputValueProps,
	DataGrid,
	GridFilterItem,
	GridFilterOperator,
	GridToolbarFilterButton,
	GridColDef
} from '@mui/x-data-grid';
import { InlineMath } from 'react-katex';
import { Checkbox, IconButton, Button } from '@mui/material';
// import { useTopicExercises } from '../hooks/use-TopicExercises';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteExercise } from '../dbOps/exercise';
import moment from 'moment';
import useFirestoreFetchExercises from '../hooks/use-fetchExercize';

const columnsData: GridColDef[] = [
	{ field: 'id', headerName: 'ID', width: 150 },
	{
		field: 'createdAt',
		headerName: 'Created At',
		width: 150,
		renderCell: (params) => {
			const milliseconds =
				params.row.createdAt.seconds * 1000 + Math.floor(params.row.createdAt.nanoseconds / 1e6);
			const date = new Date(milliseconds);

			return date.toISOString();
		}
	},
	{
		field: 'updatedAt',
		headerName: 'Updated At',
		width: 150,
		renderCell: (params) => {
			if (params.row.updatedAt) {
				return moment(params.row.updatedAt.toDate()).fromNow();
			} else return 'Not Updated';
		}
	},
	{
		field: 'from',
		headerName: 'From',
		width: 150,
		renderCell: (params) => {
			return moment(params.row.createdAt.toDate()).fromNow();
		}
	},
	{
		field: 'level',
		headerName: 'Level',
		width: 150,
		renderCell: (params) => {
			return <Rating dir="ltr" name={params.row.id} value={params.row.level} readOnly />;
		}
	},
	{
		field: 'question',
		headerName: 'Exercise',
		width: 200,
		renderCell: (params) => {
			return <InlineMath math={params.row.question} />;
		}
	},

	{
		field: 'answer',
		headerName: 'Answer',
		width: 150,
		renderCell: (params) => {
			return params.row.answer; // <InlineMath math={params.row.answer} />;
		}
	},
	{
		field: 'forTest',
		headerName: 'Exam',
		width: 60,
		renderCell: (params) => {
			return <Checkbox disabled checked={params.row.forTest} />;
		}
	},
	{
		field: 'forRehearsal',
		headerName: 'Rehearsal',
		width: 60,
		renderCell: (params) => {
			return <Checkbox disabled checked={params.row.forRehearsal} />;
		}
	},
	{
		field: 'edit',
		headerName: 'Edit',
		width: 60,
		renderCell: (params) => {
			return (
				<IconButton href={`/exercise/edit/${params.row.subtopicId}/${params.row.id}`} aria-label="edit">
					<EditIcon />
				</IconButton>
			);
		}
	},
	{
		field: 'delete',
		headerName: 'Delete',
		width: 60,
		renderCell: (params) => {
			return (
				<IconButton
					aria-label="delete"
					onClick={() => {
						console.log(params.row.id);
						if (window.confirm(`are you sure you want to delete ${params.row.question}`) === true) {
							deleteExercise(params.row.id);
						} else {
							console.log('not deleted');
						}
					}}
				>
					<DeleteIcon />
				</IconButton>
			);
		}
	}
];

function RatingInputValue(props: GridFilterInputValueProps) {
	const { item, applyValue, focusElementRef } = props;

	const ratingRef: React.Ref<any> = React.useRef(null);
	React.useImperativeHandle(focusElementRef, () => ({
		focus: () => {
			ratingRef.current.querySelector(`input[value="${Number(item.value) || ''}"]`).focus();
		}
	}));

	const handleFilterChange: RatingProps['onChange'] = (event, newValue) => {
		applyValue({ ...item, value: newValue });
	};

	return (
		<Box
			sx={{
				display: 'inline-flex',
				direction: 'ltr',
				flexDirection: 'row',
				alignItems: 'center',
				height: 48,
				pl: '20px'
			}}
		>
			<Rating
				dir="ltr"
				name="custom-rating-filter-operator"
				value={Number(item.value)}
				onChange={handleFilterChange}
				precision={1}
				ref={ratingRef}
			/>
		</Box>
	);
}

const ratingOnlyOperators: GridFilterOperator[] = [
	{
		label: 'Above',
		value: 'above',
		getApplyFilterFn: (filterItem: GridFilterItem) => {
			if (!filterItem.field || !filterItem.value || !filterItem.operator) {
				return null;
			}

			return (params): boolean => {
				return Number(params.value) >= Number(filterItem.value);
			};
		},
		InputComponent: RatingInputValue,
		InputComponentProps: { type: 'number' },
		getValueAsString: (value: number) => `${value} Stars`
	}
];

export default function TopicExerciseList({ sub_topic }: any) {
	const [ rowsData, setRowsData ] = React.useState<any[]>([]);
	const [ lastDoc, setLastDoc ] = React.useState<any>(null);
	const { topicExercises, cursor } = useFirestoreFetchExercises(sub_topic, 10, lastDoc);

	React.useEffect(
		() => {
			if (topicExercises) {
				setRowsData(topicExercises);
			}
		},
		[ topicExercises ]
	);

	const columns = React.useMemo(
		() =>
			columnsData.map(
				(col) =>
					col.field === 'level'
						? {
								...col,
								filterOperators: ratingOnlyOperators
							}
						: col
			),
		[ columnsData ]
	);

	return (
		<div style={{ height: 600, width: '100%', direction: 'ltr' }}>
			<DataGrid
				pagination
				rows={rowsData}
				columns={columns}
				slots={{
					toolbar: GridToolbarFilterButton
				}}
				initialState={{
					columns: {
						columnVisibilityModel: {}
					},
					filter: {
						filterModel: {
							items: []
						}
					}
				}}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
				<Button
					variant="contained"
					onClick={() => {
						setLastDoc(cursor);
					}}
				>
					Load More
				</Button>
			</Box>
		</div>
	);
}
