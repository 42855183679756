import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import SelectSubTopicDialog from '../../dialogs/selectSubTopic';
import DownloadExeButton from '../downloadExeButton';
import { useGradeTopics } from '../../hooks/use-gradetopics';
import { Typography } from '@mui/material';
function createData(
  name: string,
  subtopics: any,
  subtopic_count: number,
  user_selected_count: number,
  showDownload: boolean,
) {
  return {
    name,
    subtopic_count,
    user_selected_count,
    subtopic: subtopics,
    showDownload: showDownload,
  };
}
type Topic = {
  topic: string;
  topic_id: string;
};
function Row(props: { row: ReturnType<typeof createData>; student: any }) {
  const { row, student } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="right" component="th" scope="row">
          {row.name}
        </TableCell>

        <TableCell align="right">
          {row.subtopic.map((t: any, i: number) => (
            <div className="display-topics" key={i}>
              <SelectSubTopicDialog
                student={student}
                subTopic={t}
                index={i}
              ></SelectSubTopicDialog>
              <Typography>{t.name}</Typography>
            </div>
          ))}
        </TableCell>
        <TableCell align="right" component="th" scope="row">
          {row.showDownload ? (
            <DownloadExeButton
              disabled={
                row.subtopic.filter(
                  (t: any) =>
                    t.topic_id in student.selected_topics &&
                    t.id in student.selected_topics[t.topic_id] &&
                    student.selected_topics[t.topic_id][t.id]?.status !==
                      'not selected',
                ).length === 0
              }
              row={row}
              selected_topics={
                student.selected_topics[row.subtopic[0].topic_id]
              }
              studentId={student.id}
              userId={student.parentId}
            />
          ) : (
            <> </>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function StudentProgress({ student, showDownloadButton }: any) {
  const res: Record<string, any> = {};
  console.log('showDownloadButton', showDownloadButton);
  const gradeTopics = useGradeTopics(student.grade);

  gradeTopics.forEach((element: Topic) => {
    if (element?.topic in res) {
      res[element?.topic].push(element);
    } else {
      res[element?.topic] = [];
      res[element?.topic].push(element);
    }
    // ...use `element`...
  });
  const rows: any = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.keys(res).forEach(function (key, index) {
    rows.push(
      createData(
        key,
        res[key],
        res[key].length,
        res[key].length - 1,
        showDownloadButton,
      ),
    );
  });
  return (
    <TableContainer
      component={Paper}
      style={{
        paddingBottom: 0,
        marginTop: 100,
        paddingRight: 40,
        width: 800,
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead></TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <Row key={row.name} row={row} student={student} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StudentProgress;
