import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTopics } from "../hooks/use-topics";

type Topic = {
	id: string | number;
	name: string;
}

type TopicSelectProps = {
	grade: string | number;
	topic: string | number;
	handleOnChange: (event: SelectChangeEvent) => void;
}
function TopicSelect({ grade, topic, handleOnChange }: TopicSelectProps) {
	const topics = useTopics(grade.toString());

	return (
		<FormControl fullWidth>
			<InputLabel id="topic-select-label">נושא</InputLabel>
			<Select
				labelId="topic-select-label"
				id="topic-select"
				value={topic?.toString() || ''}
				onChange={handleOnChange}
				label="נושא"
				aria-label="Select topic"
			>
				{topics?.length > 0 ? (
					topics.map((t: Topic) => (
						<MenuItem value={t.id} key={t.id}>
							{t.name}
						</MenuItem>
					))
				) : (
					<MenuItem disabled>No topics available</MenuItem>
				)}
			</Select>
		</FormControl>
	);
}

export default TopicSelect;
