import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	direction: 'rtl'
	// palette: {
	// 	mode: 'light',

	// 	primary: {
	// 		main: '#FFFFFF'
	// 	},
	// 	text: {
	// 		primary: '#000000', // setting main text color to black
	// 		secondary: '#666666' // a lighter shade for secondary text
	// 	}
	// },
	// components: {
	// 	MuiButton: {
	// 		styleOverrides: {
	// 			root: {
	// 				color: '#000000' // Ensuring text color for all button types
	// 			},
	// 			contained: {
	// 				color: '#000000' // Setting the text color for contained buttons to black
	// 			}
	// 		}
	// 	}
	// }
});

export default theme;
