import React, { useState, useCallback } from 'react';
import { Grid, Typography, Avatar, TextField, Box, Paper } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import MuiPhoneNumber from 'material-ui-phone-number';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GradeSelect } from '../../gradeSelect';
import { useStudent } from '../../../hooks/use-student';
import DeleteStudent from '../../../dialogs/deleteStudent';
import { UpdateStudent } from '../../../dbOps/usersCollection';
import { ModernButton } from '../../../designSystem/Buttons/ModernButton';
import ChildSvg from '../../../assets/icons/avatars/child.svg';

// Types
type ChildInfoProps = {
	id: string;
};

type Student = {
	name: string;
	grade: string;
	phone: string;
	avatar?: string;
};

type FirebaseAuth = {
	uid: string;
};

const CONTAINER_WIDTH = 500;

export default function ChildInfo({ id }: ChildInfoProps) {
	const child = useStudent(id) as Student;
	const user = useSelector<any, FirebaseAuth>((state) => state.firebase.auth);
	const { t } = useTranslation();

	const [ formData, setFormData ] = useState<Omit<Student, 'avatar'>>({
		grade: child.grade,
		name: child.name,
		phone: child.phone
	});
	const [ nameError, setNameError ] = useState(child.name.length === 0);

	const handleGradeChange = useCallback((event: SelectChangeEvent) => {
		setFormData((prev) => ({ ...prev, grade: event.target.value }));
	}, []);

	const handlePhoneChange = useCallback((value: string | React.ChangeEvent<any>) => {
		const phoneNumber = typeof value === 'string' ? value : value.target.value;
		setFormData((prev) => ({ ...prev, phone: phoneNumber }));
	}, []);

	const handleNameChange = useCallback((value: string) => {
		setFormData((prev) => ({ ...prev, name: value }));
		setNameError(value.length === 0);
	}, []);

	const handleSaveChild = useCallback(
		async () => {
			try {
				if (nameError) return;
				await UpdateStudent(user.uid, formData.name, formData.phone, formData.grade, id);
			} catch (error) {
				console.error('Failed to update student:', error);
				// TODO: Add error notification
			}
		},
		[ user.uid, formData, id, nameError ]
	);

	return (
		<Paper elevation={3} style={{ width: CONTAINER_WIDTH, padding: '20px', borderRadius: '15px' }}>
			<Grid container spacing={4}>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Avatar
						alt={child.name}
						sx={{ width: 120, height: 120, marginBottom: '20px' }}
						src={child.avatar || ChildSvg}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" gutterBottom>
						{t('Name')}
					</Typography>
					<TextField
						fullWidth
						required
						error={nameError}
						variant="outlined"
						helperText={nameError ? t('Insert Name') : ''}
						value={formData.name}
						onChange={(event) => handleNameChange(event.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Typography variant="h6" gutterBottom>
						{t('Grade')}
					</Typography>
					<GradeSelect handleOnChange={handleGradeChange} value={formData.grade} />
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						{t('Phone')}
					</Typography>
					<MuiPhoneNumber
						dir="ltr"
						fullWidth
						defaultCountry="il"
						onChange={handlePhoneChange}
						onlyCountries={[ 'il' ]}
						value={formData.phone}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" justifyContent="space-between" mt={2}>
						<ModernButton
							variant="contained"
							onClick={handleSaveChild}
							disabled={nameError}
							sx={{ minWidth: '120px' }}
						>
							{t('Save')}
						</ModernButton>
						<DeleteStudent name={formData.name} id={id} />
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
}
