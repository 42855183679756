import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

// Ripple animation for the button
const rippleEffect = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const ModernButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  borderRadius: '12px',
  border: 0,
  color: 'white',
  padding: '12px 32px',
  boxShadow: '0 3px 15px 2px rgba(0, 0, 0, 0.1)',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 20px 4px rgba(0, 0, 0, 0.15)',
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 30%, ${theme.palette.primary.main} 90%)`,
  },
  '&:active': {
    transform: 'translateY(1px)',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '5px',
    height: '5px',
    background: 'rgba(255, 255, 255, 0.5)',
    opacity: 0,
    borderRadius: '100%',
    transform: 'scale(1, 1) translate(-50%)',
    transformOrigin: '50% 50%',
  },
  '&:active::after': {
    animation: `${rippleEffect} 0.6s ease-out`,
  },
  '& .MuiButton-startIcon': {
    marginLeft: '12px',
  },
}));
