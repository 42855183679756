import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  width: '100%',
  bottom: 0,
  padding: '10px 30px 10px ',
  color: 'white',
  '& ul': {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  '& a': {
    color: 'white',
    textDecoration: 'none',
    transition: 'opacity 0.2s ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
  '& .copyright': {
    opacity: 0.8,
    fontSize: '0.9rem',
  },
  [theme.breakpoints.down('md')]: {
    '& ul': {
      justifyContent: 'center',
      marginTop: '20px',
    },
    '& .copyright': {
      textAlign: 'center',
    },
  },
}));

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    { label: t('home'), href: '/' },
    { label: t('about'), href: '/about' },
    { label: t('contact'), href: '/contact' },
    { label: t('privacy'), href: '/privacy-policy' },
    { label: t('terms'), href: '/terms-of-service' },
  ];

  return (
    <StyledFooter>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <p className="copyright">
              &copy; {currentYear} grade-boost.com. {t('allRightsReserved')}
            </p>
          </div>
          <div className="col-md-6">
            <nav aria-label="Footer Navigation">
              <ul role="list">
                {footerLinks.map((link, index) => (
                  <li key={index} role="listitem">
                    <a href={link.href} aria-label={link.label}>
                      {link.label}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
}

export default Footer;
