import { useEffect, useState } from 'react';
import 'firebase/firestore';

import {
  getFirestore,
  collection,
  query,
  limit,
  startAfter,
  getDocs,
  where,
} from 'firebase/firestore'; // Import Firestore instance, collection, doc, query, limit, startAfter, and getDocs functions
import { useSelector } from 'react-redux';

const useFirestoreFetchExercises = (
  subTopic: string,
  pageSize: number,
  lastDoc: any,
) => {
  const [topicExercises, setTopicExercises] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState<any>(null);

  const user = useSelector((state: any) => state.firebase.auth);
  useEffect(() => {
    if (!user?.uid || !subTopic) return;

    const fetchExercises = async () => {
      setLoading(true);
      console.log(`lastDoc: ${lastDoc}`);

      try {
        const firestore = getFirestore();
        let exercisesQuery = query(
          collection(firestore, 'exercises'),
          where('subtopicId', '==', subTopic),
          limit(pageSize),
        );

        if (lastDoc) {
          exercisesQuery = query(exercisesQuery, startAfter(lastDoc));
        }

        const snapshot = await getDocs(exercisesQuery);
        const exercises = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCursor(snapshot.docs[snapshot.docs.length - 1]);
        if (exercises.length < pageSize) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setTopicExercises((prev) => [...prev, ...exercises]);
        console.log(exercises);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchExercises();
  }, [subTopic, pageSize, lastDoc]);

  return { topicExercises, loading, hasMore, cursor };
};

export default useFirestoreFetchExercises;
