import * as React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useFamily } from '../hooks/use-family';
import { useGradeTopics } from '../hooks/use-gradetopics';
import './student.css';
import SelectSubTopicDialog from '../dialogs/selectSubTopic';
import DownloadExeButton from './downloadExeButton';
import { Typography } from '@mui/material';
import ErrorBoundary from './ErrorBoundary';

function createData(
  name: string,
  subtopics: any,
  subtopic_count: number,
  user_selected_count: number,
) {
  return {
    name,
    subtopic_count,
    user_selected_count,
    subtopic: subtopics,
  };
}

const Row = (props: { row: ReturnType<typeof createData>; student: any }) => {
  const { row, student } = props;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="right" scope="row">
          {row.name}
        </TableCell>

        <TableCell align="right" scope="row">
          {row.subtopic.map((t: any, i: number) => (
            <div className="display-topics" key={i}>
              <ErrorBoundary
                fallback={
                  <Typography 
                    variant="caption" 
                    color="error"
                    sx={{ display: 'block', p: 1 }}
                  >
                    Failed to load topic selector
                  </Typography>
                }
              >
                <SelectSubTopicDialog
                  student={student}
                  subTopic={t}
                  index={i}
                />
              </ErrorBoundary>
              <Typography>{t.name}</Typography>
            </div>
          ))}
        </TableCell>
        <TableCell align="right" scope="row">
          <DownloadExeButton
            disabled={
              row.subtopic.filter(
                (t: any) =>
                  student?.selected_topics &&
                  t?.topic_id in student.selected_topics &&
                  t?.id in student.selected_topics[t.topic_id] &&
                  student.selected_topics[t.topic_id][t.id]?.status !==
                    'not selected',
              ).length === 0
            }
            row={row}
            selected_topics={student?.selected_topics?.[row.subtopic[0].topic_id]}
            studentId={student.id}
            userId={student.parentId}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

function StudentSelection({ id }: any) {
  const [grade, setGrade] = React.useState('');

  const family = useFamily();

  const student = family.filter((child: any) => child.id === id)[0];
  const gradeTopics = useGradeTopics(grade);

  React.useEffect(() => {
    if (student) {
      setGrade(student?.grade);
    }
  }, [student]);

  type Topic = {
    topic: string;
    topic_id: string;
  };
  const res: Record<string, any> = {};
  gradeTopics.forEach((element: Topic) => {
    if (element?.topic && element.topic in res) {
      res[element.topic].push(element);
    } else {
      res[element.topic] = [];
      res[element.topic].push(element);
    }
    // ...use `element`...
  });
  const rows: any = [];
  Object.keys(res).forEach(function (key) {
    rows.push(createData(key, res[key], res[key].length, res[key].length - 1));
  });

  return (
    <TableContainer
      component={'span'}
      style={{
        paddingTop: 50,
        paddingBottom: 50,
        paddingRight: 40,
        width: 800,
      }}
    >
      <Table aria-label="collapsible table">
        <TableHead></TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <Row key={row.name} row={row} student={student} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default StudentSelection;
