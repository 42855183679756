import { TextField } from '@mui/material';

type TextInputWithValidationProps = {
  value: string;
  label: string;

  ValidateInput: (value: string) => boolean;
  onError: (error: boolean) => void;
  onChange: (value: string) => void;
};

export default function TextInputWithValidation(
  props: TextInputWithValidationProps,
) {
  const { value, label, ValidateInput, onError, onChange } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const err = ValidateInput(event.target.value);
    onError(err);
    onChange(event.target.value);
  }

  return (
    <TextField
      id="standard-basic"
      label={label}
      variant="standard"
      value={value}
      onChange={handleChange}
    />
  );
}
