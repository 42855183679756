import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Avatar, Step, StepContent, StepLabel, TextField } from '@mui/material';
import firebase from 'firebase/compat/app';
import MuiPhoneNumber from 'material-ui-phone-number';
import TextInputWithValidation from './inputs/textInputValidation';
import { GradeSelect } from './gradeSelect';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { AddNewStudent } from '../dbOps/usersCollection';

type stepsType = {
  activeStep: number;
  label: string;
  optional?: boolean;
  skip?: boolean;
  content?: React.ReactNode;
  error?: boolean;
  alert?: string;
  validator?: () => void;
};

type AddStudentFlowData = {
  name: string;
  email: string;
  phone: string;
  grade: string;
  avatar: string;
};

const defaultAvatar =
  'https://firebasestorage.googleapis.com/v0/b/math-practice-2350d.appspot.com/o/studentAvatar%2Fchild.svg?alt=media&token=b83ec1d1-d8c5-4b02-be08-b1d466af3138';

export default function AddStudentFlow() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const user = useSelector((state: any) => state.firebase.auth);

  const [formData, setFormData] = React.useState<AddStudentFlowData>({
    name: '',
    email: '',
    phone: '',
    grade: 'grade-1',
    avatar: defaultAvatar,
  });
  const navigate = useNavigate();

  const [avatars, setAvatars] = React.useState<string[]>([]);
  const [selectedAvatarIndex, setSelectedAvatarIndex] = React.useState<
    number | null
  >(null);

  function validateName(name: string) {
    if (name === '' || name.length < 2) {
      return true;
    }
    return false;
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(event);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['email']: e.target.value,
    }));
    if (e.target.value === '') {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  function handlePhoneChange(value: any) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['phone']: value,
    }));
    if (value === '') {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }
  React.useEffect(() => {
    const storageRef = firebase.storage().ref();

    // List all files in a particular folder
    const folderRef = storageRef.child('studentAvatar');
    folderRef
      .listAll()
      .then(function (res) {
        const arr: string[] = [];
        res.items.forEach(function (itemRef) {
          // Get the URL with access token
          itemRef.getDownloadURL().then(function (url) {
            // Use the URL here
            arr.push(url);
            console.log(url);
          });
        });
        console.log(`arr = ${arr}`);
        setAvatars(arr);
      })
      .catch(function (error) {
        // Handle any errors here
        console.log(error);
      });
  }, []);
  const steps: stepsType[] = [
    {
      activeStep: 0,
      label: 'שם',
      optional: false,
      skip: false,
      error: nameError,
      alert: 'name must be filled and larger than 2 characters',
      validator: () => {
        setNameError(validateName(formData.name));
      },
      content: (
        <StepContent>
          <TextInputWithValidation
            value={formData.name}
            label="שם"
            onChange={(v: any) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                ['name']: v,
              }));
            }}
            onError={(e: any) => {
              setNameError(e);
            }}
            ValidateInput={validateName}
          ></TextInputWithValidation>
        </StepContent>
      ),
    },
    {
      activeStep: 1,
      label: 'כיתה',
      optional: false,
      skip: false,
      content: (
        <StepContent>
          <GradeSelect
            style={{ width: '100%' }}
            name="grade"
            handleOnChange={handleInputChange}
            value={formData.grade}
          />
        </StepContent>
      ),
    },
    {
      activeStep: 2,
      label: 'טלפון',
      optional: true,
      skip: true,
      error: phoneError,
      validator: () => {
        if (formData.phone === '') {
          return false;
        } else {
          return true;
        }
      },
      content: (
        <StepContent>
          <MuiPhoneNumber
            defaultCountry={'il'}
            value={formData.phone}
            onChange={handlePhoneChange}
          />
        </StepContent>
      ),
    },
    {
      activeStep: 3,
      label: 'דוא"ל',
      optional: true,
      skip: true,
      error: emailError,
      content: (
        <StepContent>
          <TextField
            id="standard-basic"
            label=""
            variant="standard"
            value={formData.email}
            onChange={handleEmailChange}
          />
        </StepContent>
      ),
    },
    {
      activeStep: 4,
      label: 'אווטר',
      optional: false,
      skip: false,
      content: (
        <StepContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              alignItems: 'center',
            }}
          >
            {avatars.map((avatar: string, index) => (
              <div
                key={index}
                style={{
                  margin: '10px',
                }}
              >
                <Avatar
                  alt={`avatar-${index}`}
                  sx={{ width: 80, height: 80 }}
                  src={avatar}
                  onClick={() => {
                    setSelectedAvatarIndex(index);
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      ['avatar']: avatar,
                    }));
                  }}
                  style={{
                    border:
                      selectedAvatarIndex === index ? '2px solid blue' : 'none',
                    boxSizing: 'border-box',
                    padding: '10px',
                  }}
                />
              </div>
            ))}
          </div>
        </StepContent>
      ),
    },
  ];

  const handleNext = () => {
    let newSkipped = skipped;
    if (steps[activeStep].validator) {
      steps[activeStep].validator();
    }
    if (steps[activeStep].skip) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function handleSubmit() {
    console.log(formData);

    AddNewStudent(
      formData.name,
      formData.email,
      formData.phone,
      formData.grade,
      formData.avatar,
      user,
    );

    setFormData({
      name: '',
      email: '',
      phone: '',
      grade: 'grade-1',
      avatar: defaultAvatar,
    });
    navigate('/');
  }

  const handleSkip = () => {
    if (steps[activeStep].optional === false) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (step.optional) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (step.skip) {
            stepProps.completed = false;
          }
          if (step.error) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                {step.alert}
              </Typography>
            );
          }
          labelProps.error = step.error;

          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
              {step.content}
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>אפס</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              קודם
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {steps[activeStep].skip && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                דלג
              </Button>
            )}
            <Button
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
            >
              {steps[activeStep].error == false
                ? activeStep === steps.length - 1
                  ? 'סיים'
                  : 'הבא'
                : 'הבא'}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
