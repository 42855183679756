import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSubTopics } from '../hooks/use-subtopics';

type SubTopicSelectProps = {
	grade: string;
	topic: string;
	sub_topic: string;
	handleOnChange: (event: any) => void;
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
	minWidth: 200,
	margin: theme.spacing(1),
	'& .MuiInputLabel-root': {
		color: theme.palette.text.secondary
	},
	'& .MuiSelect-select': {
		padding: theme.spacing(1.5)
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: theme.palette.primary.main
		}
	}
}));

function SubTopicSelect({ grade, topic, sub_topic, handleOnChange }: SubTopicSelectProps) {
	const subTopics = useSubTopics(grade, topic);

	return (
		<StyledFormControl>
			<InputLabel id="subtopic-select-label">נושא</InputLabel>
			<Select
				labelId="subtopic-select-label"
				id="subtopic-select"
				value={sub_topic}
				onChange={handleOnChange}
				label="נושא"
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 300
						}
					}
				}}
			>
				{subTopics.map((subtopic: { id: string; name: string }) => (
					<MenuItem value={subtopic.id} key={subtopic.id}>
						{subtopic.name}
					</MenuItem>
				))}
			</Select>
		</StyledFormControl>
	);
}

export default SubTopicSelect;
