import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { Link, useNavigate } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { signInWithGoogle, signOutGoogle } from '../firebase';
import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import Notification from './notifications/notifications';

import {
  //   applicationRoutes,
  //   categoriesRoutes,
  pagesRoutes,
  IRoute,
} from '../routes/router';
import { useTranslation } from 'react-i18next';
import { useNotifications } from '../hooks/use-notifications';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

type AppBarProps = MuiAppBarProps & {
  open?: boolean;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginRight: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const user = useSelector((state: any) => state.firebase.auth);
  const claims = useSelector((state: any) => state.userClaims.claims);
  const [notificationOpen, setNotificationOpen] = useState(false);

  const notifications = useNotifications();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSignIn = () => {
    signInWithGoogle();
    handleMenuClose();
  };
  const handleSignOut = () => {
    signOutGoogle();
    handleMenuClose();
    navigate('/');
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* {user.uid ? (
        <MenuItem>
          <IconButton>
            <SettingsIcon></SettingsIcon>
          </IconButton>
          <p>Settings</p>
        </MenuItem>
      ) : (
        []
      )} */}
      {user.uid ? (
        <MenuItem onClick={handleSignOut}>
          <IconButton>
            <LogoutIcon></LogoutIcon>
          </IconButton>
          <p>{t('Sign out')}</p>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleSignIn}>
          <IconButton>
            <LoginIcon></LoginIcon>
          </IconButton>
          <p>{t('Sign In')}</p>
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton>
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton onClick={() => setNotificationOpen(true)}>
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Notification
          open={notificationOpen}
          onClose={() => setNotificationOpen(false)}
        />
      </MenuItem>
      <MenuItem>
        <QRCode value={'www.grade-boost.com'} size={68} />
      </MenuItem>
      {user.uid ? (
        <MenuItem onClick={handleSignOut}>
          <IconButton>
            <LogoutIcon></LogoutIcon>
          </IconButton>
          <p>{t('Sign out')}</p>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleSignIn}>
          <IconButton>
            <LoginIcon></LoginIcon>
          </IconButton>
          <p>{t('Sign In')}</p>
        </MenuItem>
      )}
      {/* {user.uid ? (
        <MenuItem>
          <IconButton>
            <SettingsIcon></SettingsIcon>
          </IconButton>
          <p>Settings</p>
        </MenuItem>
      ) : (
        []
      )} */}
    </Menu>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="show new notifications"
              color="inherit"
              onClick={() => setNotificationOpen(true)}
            >
              <Badge badgeContent={notifications.length} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Notification
              open={notificationOpen}
              onClose={() => setNotificationOpen(false)}
            />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
                alt={user.name}
                src={user.uid ? user.photoURL : null}
              ></Avatar>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <Avatar
                alt={user.name}
                src={user.uid ? user.photoURL : null}
              ></Avatar>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        PaperProps={{
          sx: {
            left: 'unset !important',
            right: '0 !important',
          },
        }}
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pagesRoutes
            .filter((route: IRoute) => route.icon != null)
            .map((route: IRoute, index) =>
              route.adminGuard && !claims?.admin ? (
                <ListItem button key={`menuI-${index}`}></ListItem>
              ) : (
                <Link
                  key={index}
                  style={{ textDecoration: 'none', color: 'black' }}
                  to={route.path!}
                  onClick={handleDrawerClose}
                >
                  <ListItem button key={`menuI-${index}`}>
                    <ListItemIcon>{route.icon!}</ListItemIcon>
                    <ListItemText primary={t(route.name)} />
                  </ListItem>
                </Link>
              ),
            )}
          <Divider />
          {user.uid ? (
            <ListItem button key={`menuI-sign-out`} onClick={handleSignOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={t('Sign out')} />
            </ListItem>
          ) : (
            <ListItem button key={`menuI-sign-in`} onClick={handleSignIn}>
              <ListItemIcon>
                <LoginIcon />
              </ListItemIcon>
              <ListItemText primary={t('Sign In')} />
            </ListItem>
          )}
        </List>
      </Drawer>
      <Main open={open}>{/* <DrawerHeader /> */}</Main>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
