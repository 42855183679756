import React, { useState } from 'react';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import {
	Button,
	Checkbox,
	Chip,
	SelectChangeEvent,
	TextField,
	Paper,
	Grid,
	Typography,
	FormControlLabel,
	Box,
	Link,
	Divider
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router';
import { GradeSelect } from '../../components/gradeSelect';
import SubTopicSelect from '../../components/subTtopicSelect';
import { useSnackbar } from 'notistack';
import { saveExercise } from '../../dbOps/exercise';
import TopicSelect from '../../components/topicSelect';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

export default function ExerciseNew() {
	const { gradeId, topicId, subtopicId } = useParams();

	const [ eq, setEq ] = useState('');
	const [ ans, setAns ] = useState('');
	const [ tags, setTags ] = useState<string[]>([]);
	const [ curTag, setCurTag ] = useState('');
	const [ isForTest, setIsForTest ] = useState<boolean>(false);
	const [ isForRehearsal, setIsForRehearsal ] = useState<boolean>(false);
	const [ level, setLevel ] = useState<string>('0');
	const [ grade, setGrade ] = useState(gradeId || '');
	const [ topic, setTopic ] = useState(topicId || '');
	const [ subTopic, setSubTopic ] = useState(subtopicId || '');
	// const [id, setId] = useState('');
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleSave = () => {
		const l: number = +level;
		saveExercise(grade, topic, subTopic, eq, ans, l, tags, isForTest, isForRehearsal, '').then(() => {
			enqueueSnackbar('Saved', { variant: 'success' });
			navigate(-1);
		});
	};
	const handleGradeChange = (event: SelectChangeEvent) => {
		setGrade(event.target.value);
		console.log(grade);
	};

	const handleTopicChange = (event: SelectChangeEvent) => {
		setTopic(event.target.value);
	};
	const handleSubTopicChange = (event: SelectChangeEvent) => {
		setSubTopic(event.target.value);
	};
	const handleTagDelete = (tag: string) => {
		const tagsArr = tags.filter((item) => item !== tag);
		setTags(tagsArr);
	};
	const handleTagAdd = () => {
		if (curTag !== '') {
			const tagsArr = [ curTag, ...tags ];
			setTags(tagsArr);
			setCurTag('');
		}
	};
	return (
		<Box sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
			<Paper elevation={3} sx={{ p: 4, backgroundColor: '#ffffff' }}>
				<Typography
					variant="h4"
					gutterBottom
					sx={{
						color: 'primary.main',
						fontWeight: 'bold',
						mb: 3
					}}
				>
					Create New Exercise
				</Typography>

				{/* LaTeX Editor Button */}
				<Box sx={{ mb: 4 }}>
					<Link
						href="https://latex.codecogs.com/eqneditor/editor.php"
						target="_blank"
						sx={{ textDecoration: 'none' }}
					>
						<Button
							variant="contained"
							color="secondary"
							startIcon={<EditIcon />}
							sx={{
								borderRadius: 2,
								textTransform: 'none',
								boxShadow: 2
							}}
						>
							Open LaTeX Editor
						</Button>
					</Link>
				</Box>

				<Grid container spacing={4}>
					{/* Question & Answer Sections */}
					{[ 'Question', 'Answer' ].map((section, index) => (
						<Grid item xs={12} key={section}>
							<Paper
								elevation={0}
								sx={{
									p: 3,
									backgroundColor: 'grey.50',
									borderRadius: 2
								}}
							>
								<Typography
									variant="h6"
									gutterBottom
									sx={{
										color: 'text.primary',
										fontWeight: 500,
										mb: 2
									}}
								>
									{section}
								</Typography>
								<TextField
									id={`${section.toLowerCase()}-input`}
									placeholder={`Enter ${section.toLowerCase()} in LaTeX`}
									variant="outlined"
									value={index === 0 ? eq : ans}
									onChange={(e) => (index === 0 ? setEq(e.target.value) : setAns(e.target.value))}
									fullWidth
									multiline
									rows={3}
									sx={{
										'& .MuiOutlinedInput-root': {
											backgroundColor: 'white',
											'&:hover fieldset': {
												borderColor: 'primary.main'
											}
										}
									}}
								/>
								<Paper
									sx={{
										p: 2,
										mt: 2,
										bgcolor: 'white',
										border: '1px solid',
										borderColor: 'grey.200',
										borderRadius: 1
									}}
								>
									<Typography variant="subtitle2" gutterBottom color="text.secondary">
										Preview:
									</Typography>
									<InlineMath math={index === 0 ? eq : ans} />
								</Paper>
							</Paper>
						</Grid>
					))}

					{/* Exercise Details Section */}
					<Grid item xs={12}>
						<Divider sx={{ my: 4 }} />
						<Typography
							variant="h6"
							gutterBottom
							sx={{
								color: 'text.primary',
								fontWeight: 500,
								mb: 3
							}}
						>
							Exercise Details
						</Typography>

						<Paper elevation={0} sx={{ p: 3, backgroundColor: 'grey.50', borderRadius: 2 }}>
							{/* Tags Section */}
							<Box sx={{ mb: 4 }}>
								<Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
									Tags
								</Typography>
								<Grid container spacing={1} alignItems="center">
									<Grid item xs>
										<TextField
											id="tag-input"
											placeholder="Add tags and press Enter"
											variant="outlined"
											value={curTag}
											fullWidth
											size="small"
											sx={{ backgroundColor: 'white' }}
											onChange={(e) => setCurTag(e.target.value)}
											onKeyPress={(e) => e.key === 'Enter' && handleTagAdd()}
										/>
									</Grid>
									<Grid item>
										<Button
											variant="contained"
											onClick={handleTagAdd}
											startIcon={<AddIcon />}
											sx={{ height: 40 }}
										>
											Add Tag
										</Button>
									</Grid>
								</Grid>
								<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
									{tags.map((t, i) => (
										<Chip
											label={t}
											key={i}
											onDelete={() => handleTagDelete(t)}
											color="primary"
											variant="outlined"
											sx={{ borderRadius: 1 }}
										/>
									))}
								</Box>
							</Box>

							{/* Classification Section */}
							<Box sx={{ mb: 4 }}>
								<Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
									{t('class')}
								</Typography>
								<Grid container spacing={3}>
									<Grid item xs={12} md={4}>
										<GradeSelect handleOnChange={handleGradeChange} value={grade} />
									</Grid>
									<Grid item xs={12} md={4}>
										<TextField
											type="number"
											InputProps={{
												inputProps: { max: 5, min: 1 }
											}}
											label="Difficulty Level"
											value={level}
											onChange={(e) => setLevel(e.target.value)}
											fullWidth
											sx={{ backgroundColor: 'white' }}
										/>
									</Grid>
									<Grid item xs={12} md={4}>
										<TopicSelect grade={grade} handleOnChange={handleTopicChange} topic={topic} />
									</Grid>
									<Grid item xs={12}>
										<SubTopicSelect
											grade={grade}
											topic={topic}
											handleOnChange={handleSubTopicChange}
											sub_topic={subTopic}
										/>
									</Grid>
								</Grid>
							</Box>

							{/* Exercise Type Section */}
							<Box>
								<Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
									Exercise Type
								</Typography>
								<Box sx={{ display: 'flex', gap: 3 }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={isForTest}
												onChange={() => setIsForTest(!isForTest)}
												color="primary"
											/>
										}
										label={t('for exam')}
									/>
									<FormControlLabel
										control={
											<Checkbox
												checked={isForRehearsal}
												onChange={() => setIsForRehearsal(!isForRehearsal)}
												color="primary"
											/>
										}
										label={t('for rehearsal')}
									/>
								</Box>
							</Box>
						</Paper>
					</Grid>
				</Grid>

				{/* Save Button */}
				<Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						variant="contained"
						size="large"
						onClick={handleSave}
						sx={{
							minWidth: 200,
							height: 48,
							borderRadius: 2,
							textTransform: 'none',
							fontWeight: 'bold'
						}}
					>
						Save Exercise
					</Button>
				</Box>
			</Paper>
		</Box>
	);
}
