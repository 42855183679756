import './topic.css';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { IconButton, TextField } from '@mui/material';
import { useParams } from 'react-router';
import { db } from '../../../firebase';
import { useSubTopics } from '../../../hooks/use-subtopics';
import BackButton from '../../../components/buttons/back/BackButton';
import { useTopic } from '../../../hooks/use-topic';
import EditableTextField from '../../../components/editableTextField';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.primary,
  height: 60,
  lineHeight: '60px',
  alignContent: 'center',
}));

function Topic() {
  const { grade, id } = useParams();
  const [newTopic, setNewTopic] = useState('');

  const subtopics = useSubTopics(grade as string, id as string);
  const topic = useTopic(grade as string, id as string);

  const handleTopicAdd = () => {
    db.collection('topics').doc(grade).collection('sub_topics').add({
      topic_id: id,
      name: newTopic,
      grade: grade,
      topic: topic.name,
    });
    setNewTopic('');
  };

  useEffect(() => {
    // let subs = topics.filter((topic: any) => topic.grade === grade);
    // setGradeTopics(subs[0].topics);
  }, [grade]);

  return (
    <div className="topic">
      <div className="topic-grade-select"></div>
      <div className="topic_add">
        <TextField
          id="standard-basic"
          label="נושא חדש"
          variant="standard"
          style={{ width: 300 }}
          value={newTopic}
          onChange={(e) => {
            setNewTopic(e.target.value);
          }}
        />
        <IconButton onClick={handleTopicAdd}>
          <AddIcon></AddIcon>
        </IconButton>
      </div>

      <div className="topic_data">
        <Grid
          className="topic_container"
          container
          spacing={{ xs: 2, md: 4 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {subtopics.map((subtopic: any) => (
            <Grid item xs={2} sm={4} md={4} key={subtopic.id}>
              <Item key={subtopic.id} elevation={16}>
                <EditableTextField
                  title={subtopic.name}
                  classname=""
                  dbPath={`topics/${grade}/sub_topics`}
                  docId={subtopic.id}
                  clickNavPath={`/backoffice/grades/${grade}/${id}/${subtopic.id}`}
                ></EditableTextField>
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="topic_nav">
        <BackButton />
      </div>
    </div>
  );
}

export default Topic;
