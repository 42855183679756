import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { db } from '../firebase';
import './selectTopics.css';
import { RadioGroup } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//in case of change need to update i18n.json
enum Progress {
  NOT_SELECTED = 'not selected',
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
  COMPLETED = 'completed',
}

enum Styles {
  NOT_SELECTED = 'progress-not-selected',
  SELECTED = 'progress-selected',
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export type DialogTopicSelectProps = {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
};

function BootstrapTopicSelectDialog(props: DialogTopicSelectProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function TeacherSelectSubTopicDialog(params: any) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const { student = {}, subTopic = {}, index } = params;
  const { t } = useTranslation();

  const {
    selected_topics = {},
    parentId = '',
    id = ''
  } = student;

  const {
    topic_id = '',
    id: subTopicId = '',
    name: subtopicName = '',
    topic: topicName = ''
  } = subTopic;

  const progress_types = Object.values(Progress);
  const admin = useSelector(
    (state: any) =>
      state.userClaims?.claims?.admin || state.userClaims?.claims?.teacher,
  );

  const getTopicData = () => {
    if (!selected_topics) return null;
    if (!topic_id) return null;
    return selected_topics[topic_id] || null;
  };

  const getSubtopicData = () => {
    const topicData = getTopicData();
    if (!topicData) return null;
    if (!subTopicId) return null;
    return topicData[subTopicId] || null;
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleClickOpen = () => {
    if (!admin) return;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    if (!topic_id || !subTopicId || !parentId || !id) {
      console.error('Missing required IDs');
      return;
    }

    const subtopicData = getSubtopicData();
    const markers_value = {
      ...(subtopicData?.markers || {}),
    };
    markers_value[value] = markers_value[value] || null;

    const updateData = {
      [`selected_topics.${topic_id}.${subTopicId}`]: {
        status: value,
        subtopic_name: subtopicName,
        topic_id: topic_id,
        topic_name: topicName,
        markers: { ...markers_value },
        ended_date: subtopicData?.ended_date || null,
      },
    };

    try {
      db.collection('users')
        .doc(parentId)
        .collection('children')
        .doc(id)
        .update(updateData);
      setOpen(false);
    } catch (error) {
      console.error('Error updating topic:', error);
    }
  };

  const getSelectionCssClass = (topic_progress: any) => {
    if (!student?.selected_topics) return Styles.NOT_SELECTED;
    
    if (
      (topic_id in (student.selected_topics || {}) &&
        subTopicId in (student.selected_topics[topic_id] || {}) &&
        topic_progress == null) ||
      topic_progress === Progress.NOT_SELECTED
    )
      return Styles.NOT_SELECTED;
    if (topic_progress === Progress.BEGINNER) return Styles.SELECTED;
    if (topic_progress === Progress.INTERMEDIATE) return Styles.SELECTED;
    if (topic_progress === Progress.ADVANCED) return Styles.SELECTED;
    if (topic_progress === Progress.COMPLETED) return Styles.SELECTED;
  };
  const getSelectionCssFill = (topic_progress: any) => {
    if (!topic_progress || topic_progress === Progress.NOT_SELECTED)
      return { '--progress-fill': `0%` } as React.CSSProperties;
    if (topic_progress === Progress.BEGINNER)
      return { '--progress-fill': `15%` } as React.CSSProperties;
    if (topic_progress === Progress.INTERMEDIATE)
      return { '--progress-fill': `50%` } as React.CSSProperties;
    if (topic_progress === Progress.ADVANCED)
      return { '--progress-fill': `75%` } as React.CSSProperties;
    if (topic_progress === Progress.COMPLETED)
      return { '--progress-fill': `100%` } as React.CSSProperties;
  };
  return (
    <div className="topic-select-button">
      <Button onClick={handleClickOpen}>
        <div
          className={`${getSelectionCssClass(
            student?.selected_topics && topic_id in student.selected_topics
              ? student.selected_topics[topic_id]?.[subTopicId]?.status ?? Progress.NOT_SELECTED
              : Progress.NOT_SELECTED
          )} ${subTopicId}-progress`}
          style={getSelectionCssFill(
            student?.selected_topics && topic_id in student.selected_topics
              ? student.selected_topics[topic_id]?.[subTopicId]?.status ?? Progress.NOT_SELECTED
              : Progress.NOT_SELECTED
          )}
        >
          <div className="topic-progress">{index + 1}</div>
        </div>
      </Button>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapTopicSelectDialog
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {subTopic?.name}
        </BootstrapTopicSelectDialog>
        <DialogContent dividers>
          <Typography gutterBottom>{subTopic?.description}</Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={
              student?.selected_topics && topic_id in (student.selected_topics || {})
                ? student.selected_topics[topic_id]?.[subTopicId]?.status || Progress.NOT_SELECTED
                : Progress.NOT_SELECTED
            }
            name="radio-buttons-group"
            onChange={handleRadioChange}
          >
            {progress_types.map((progress_type) => {
              return (
                <FormControlLabel
                  key={progress_type}
                  value={progress_type}
                  control={<Radio />}
                  label={t(progress_type)}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSelect}>
            שמור
          </Button>
          <Button autoFocus onClick={() => setOpen(false)}>
            סגור
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default function SelectSubTopicDialog({ student, subTopic, index }: any) {
  if (!student || !subTopic) {
    console.error('Missing required props in SelectSubTopicDialog');
    return null;
  }

  try {
    return TeacherSelectSubTopicDialog({ student, subTopic, index });
  } catch (error) {
    console.error('Error in SelectSubTopicDialog:', error);
    return null;
  }
}
